import React, { FC } from 'react';
import NextLink from 'next/link';
import Image from 'next/image';

import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import LanguageIcon from '@mui/icons-material/Language';

import { pages } from '../navigates/NavBar';

import IMainProps from '../../interfaces/IMainProps';
import SocialStack from '../widgets/SocialStack';
import ContactStack from '../widgets/ContactStack';

const Footer: FC<IMainProps> = (props: IMainProps) => {
    const { locale } = props;
    const theme = useTheme();

    const appPhone = locale === 'ru-RU' ? process.env.appRuPhone : process.env.appUzPhone;
    const telegram = locale === 'ru-RU' ? process.env.appRuTelegram : process.env.appUzTelegram;

    const Copyright = () => (
        <>
            <Typography
                variant='body2'
            >
                {'Copyright © '}{process.env.personName}, {new Date().getFullYear()}{' '}
            </Typography>
            <Stack direction='row'
                spacing={0.5}
                justifyContent='start'
                alignItems='center'
            >
                <Link aria-label='RU/UZ' href={process.env.appUzURL} color='inherit' variant='body2' title='RU/UZ' sx={{ '&:hover': { color: theme.palette.secondary.main } }} >
                    <LanguageIcon color='inherit' sx={{ fontSize: 12 }} />
                </Link>
                <NextLink href='/policy' passHref>
                    <Link rel='nofollow' color='inherit' variant='body2' title='Политика конфиденциальности' >
                        Политика конфиденциальности
                    </Link>
                </NextLink>
            </Stack>
            <Typography
                variant='body2'
                component='p'
            >
                Цены на сайте не являются публичной офертой!
            </Typography>
            <Typography variant="body2" component='p'>
                Разработка: <Typography sx={{ color:'inherit' }} component={Link} target='_blank' rel='nofollow' href='https://aelinkov.pro' title='Веб разработка, дизайн и СЕО в кратчайшие сроки'>aelinkov.pro</Typography>
            </Typography>
        </>
    );

    return (
        <Box
            component='footer'
            sx={{
                p: 3,
                mt: 'auto',
                backgroundColor: theme.palette.primary.main,
            }}
        >
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Typography
                            variant='h6'
                            component='h3'
                            gutterBottom
                            sx={{ color: theme.palette.secondary.dark, mt: '10px' }}
                        >
                            {process.env.personName}
                        </Typography>
                        <Typography
                            variant='body1'
                            component='p'
                        >
                            {process.env.appDescription}
                        </Typography>
                        <Copyright />
                    </Grid>
                    <Grid item xs={8} sm={4} md={3} lg={3}>
                        <Typography
                            variant='h6'
                            component='h3'
                            gutterBottom
                            sx={{ color: theme.palette.secondary.dark, mt: '10px' }}
                        >
                            Контакты
                        </Typography>
                        <ContactStack locale={locale} />
                    </Grid>
                    <Grid item xs={4} sm={2} md={2} lg={2}>
                        <Typography
                            variant='h6'
                            component='h3'
                            gutterBottom
                            sx={{ color: theme.palette.secondary.dark, mt: '10px' }}
                        >
                            Меню
                        </Typography>
                        <Stack direction='column' spacing={1}>
                            {pages.map((page: { name: string, slug: string }) => (
                                <NextLink href={page.slug} key={page.name} passHref>
                                    <Link
                                        variant='body1'
                                        color='inherit'
                                        sx={{
                                            textDecoration: 'none',
                                            '&:hover': {
                                                color: theme.palette.secondary.dark,
                                            }
                                        }}
                                    >
                                        {page.name}
                                    </Link>
                                </NextLink>
                            ))}
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2} lg={2} sx={{ textAlign: 'center' }}>
                        <Image
                            src='/footer.svg'
                            alt={locale === 'ru-RU' ? process.env.appRuName : process.env.appUzName}
                            width={410}
                            height={300}
                            loading='lazy'
                        />
                        <SocialStack pyStack={1} spacingStack={2} sizeIcon={35} justifyContentStack={'center'} appPhone={appPhone as string} telegram={telegram as string} />

                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}
export default Footer;