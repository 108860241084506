import React, { FC } from 'react';

import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import VkIcon from '../icons/social/VkIcon'
import FacebookIcon from '../icons/social/FacebookIcon';
import InstagramIcon from '../icons/social/InstagramIcon';
import WhatsappIcon from '../icons/social/WhatsappIcon';
import TelegramIcon from '../icons/social/TelegramIcon';
import PangenesIcon from '../icons/social/PangenesIcon';

interface ISocialStackProps {
    appPhone: string,
    telegram: string,
    sizeIcon: number,
    spacingStack: number,
    pyStack: number,
    justifyContentStack: 'start' | 'center'
}


const SocialStack: FC<ISocialStackProps> = (props: ISocialStackProps) => {
    const theme = useTheme();
    const { appPhone, telegram, sizeIcon, spacingStack, pyStack, justifyContentStack } = props;

    return (
        <Stack direction='row'
            spacing={spacingStack}
            justifyContent={justifyContentStack}
            alignItems='center'
            sx={{
                py: pyStack
            }}
        >
            <Link
                href='https://www.facebook.com/groups/292093189034432'
                title='Группа на facebook'
                target='_blank'
                rel='noopener noreferrer'
                color={theme.palette.secondary.main}
                sx={{
                    '&:hover': {
                        color: theme.palette.secondary.dark,
                    }
                }}
            >
                <FacebookIcon size={sizeIcon} />
            </Link>
            <Link
                href='https://instagram.com/biologusvulgaris'
                title='Группа в instagram'
                target='_blank'
                rel='noopener noreferrer'
                color={theme.palette.secondary.main}
                sx={{
                    '&:hover': {
                        color: theme.palette.secondary.dark,
                    }
                }}
            >
                <InstagramIcon size={sizeIcon} />
            </Link>
            <Link
                href='https://vk.com/pangenes'
                title='Группа в vk'
                target='_blank'
                rel='noopener noreferrer'
                color={theme.palette.secondary.main}
                sx={{
                    '&:hover': {
                        color: theme.palette.secondary.dark,
                    }
                }}
            >
                <VkIcon size={sizeIcon} />
            </Link>
            <Link
                href={`https://wa.me/${(appPhone as string).replace(/[^+0-9]/g, '')}`}
                title='Написать мне в whatsapp'
                target='_blank'
                rel='noopener noreferrer'
                color={theme.palette.secondary.main}
                sx={{
                    '&:hover': {
                        color: theme.palette.secondary.dark,
                    }
                }}
            >
                <WhatsappIcon size={sizeIcon} />
            </Link>
            <Link
                href={`https://t.me/${telegram}`}
                title='Написать мне в telegram'
                target='_blank'
                rel='noopener noreferrer'
                color={theme.palette.secondary.main}
                sx={{
                    '&:hover': {
                        color: theme.palette.secondary.dark,
                    }
                }}
            >
                <TelegramIcon size={sizeIcon} />
            </Link>
            <Link
                href='https://pangenes.ru'
                title='Мой блог Pangenes.ru'
                target='_blank'
                rel='noopener noreferrer'
                color={theme.palette.secondary.main}
                sx={{
                    '&:hover': {
                        color: theme.palette.secondary.dark,
                    }
                }}
            >
                <PangenesIcon size={sizeIcon} />
            </Link>
        </Stack>
    );
}
export default SocialStack;