import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import PhoneIcon from '@mui/icons-material/Phone';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import NextLink from 'next/link';
import Link from '@mui/material/Link';
import Image from 'next/image'
import { useRouter } from 'next/router';

import AppDrawer from '../drawer/AppDrawer';

import IMainProps from '../../interfaces/IMainProps';

export const pages = [
  {
    name: 'Обо мне',
    slug: '#AboutUsSection'
  },
  {
    name: 'Услуги',
    slug: '#ServicesSection'
  },
  {
    name: 'Цены',
    slug: '#PricesSection'
  },
  {
    name: 'Контакты',
    slug: '#ContactsSection'
  },
  {
    name: 'Отзывы',
    slug: '#ReviewsSection'
  },
];

const NavBar = (props: IMainProps) => {
  const { locale } = props;
  const theme = useTheme();
  const { asPath } = useRouter();

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const appPhone = locale === 'ru-RU' ? process.env.appRuPhone : process.env.appUzPhone;
  const appName = locale === 'ru-RU' ? process.env.appRuName : process.env.appUzName;

  return (
    <AppBar position='fixed'>
      <Container maxWidth='xl'>
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, justifyContent: 'flex-start', display: 'flex', cursor: 'pointer' }}>
            <NextLink href='/' passHref>
              <Link>
                <Image
                  loading='eager'
                  src='/logo.svg'
                  alt={appName}
                  width='70'
                  height='60'
                />
              </Link>
            </NextLink>
            <NextLink href='/' passHref>
              <Typography
                variant='h4'
                component={Link}
                color={theme.palette.secondary.main}
                underline='none'
                noWrap
                sx={{ mt: '8px', ml: '10px', display: { lg: 'flex', xs: 'none' } }}
              >
                {appName}
              </Typography>
            </NextLink>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Link href={`tel:${(appPhone as string).replace(/[^+0-9]/g, '')}`} color={theme.palette.secondary.main} underline='none'
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap'
              }}
            >
              <PhoneIcon /> <Typography variant='h5'>{appPhone}</Typography>
            </Link>
          </Box>
          <Box sx={{ flexGrow: 1, justifyContent: 'flex-end', display: { xs: 'flex', md: 'none' } }}>
            <AppDrawer pages={pages} />
          </Box>
          <Box sx={{ flexGrow: 1, justifyContent: 'flex-end', display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <NextLink href={page.slug} key={page.name} passHref>
                <Button
                  key={page.name}
                  aria-label={page.name}
                  LinkComponent={Link}
                  sx={{
                    color: asPath === '/' + page.slug ? theme.palette.secondary.main : theme.palette.secondary.dark,
                  }}
                >
                  {page.name}
                </Button>
              </NextLink>
            ))}
            <Button
              href='https://pangenes.ru'
              key='pangenes.ru'
              aria-label='pangenes.ru'
              target='_blank'
              component='a'
              sx={{
                color: theme.palette.secondary.dark,
              }}
            >
              Блог
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default NavBar;