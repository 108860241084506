import { FC } from 'react';
import IIconProps from '../../../interfaces/IIconProps';

const VkIcon: FC<IIconProps> = (props: IIconProps) => {
    const { size } = props;
    return (
        <svg
            fill='currentColor'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 50 50' width={`${size}px`} height={`${size}px`}>
            <path d='M 9 4 C 6.24 4 4 6.24 4 9 L 4 41 C 4 43.76 6.24 46 9 46 L 41 46 C 43.76 46 46 43.76 46 41 L 46 9 C 46 6.24 43.76 4 41 4 L 9 4 z M 17 13 L 25.029297 13 C 26.769297 13 34 12.999844 34 18.339844 C 34 19.469844 33.720625 20.459844 33.140625 21.339844 C 32.560625 22.219844 31.390859 22.859297 30.380859 23.279297 L 31.189453 23.400391 C 32.639453 23.700391 33.809453 24.300469 34.689453 25.230469 C 35.559453 26.150469 36 27.390937 36 28.960938 C 36 30.100937 35.780078 31.110703 35.330078 31.970703 C 34.890078 32.840703 34.290781 33.549141 33.550781 34.119141 C 32.660781 34.789141 31.679375 35.280312 30.609375 35.570312 C 29.549375 35.850313 28.210313 36 26.570312 36 L 17 36 L 17 13 z M 23 18 L 23 22 L 26 22 C 27.105 22 28 21.105 28 20 C 28 18.895 27.105 18 26 18 L 23 18 z M 23 26 L 23 31 L 27.5 31 C 28.88 31 30 29.88 30 28.5 C 30 27.12 28.88 26 27.5 26 L 23 26 z' />
        </svg>
    );
}

export default VkIcon;