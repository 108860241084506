import { FC } from 'react';
import IIconProps from '../../../interfaces/IIconProps';

const FacebookIcon:FC<IIconProps> = (props: IIconProps) => {
    const { size } = props;
    return (
        <svg
            fill='currentColor'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 50 50' width={`${size}px`} height={`${size}px`}>
            <path d='M41,4H9C6.24,4,4,6.24,4,9v32c0,2.76,2.24,5,5,5h32c2.76,0,5-2.24,5-5V9C46,6.24,43.76,4,41,4z M37,19h-2c-2.14,0-3,0.5-3,2 v3h5l-1,5h-4v15h-5V29h-4v-5h4v-3c0-4,2-7,6-7c2.9,0,4,1,4,1V19z' />
        </svg>
    );
}

export default FacebookIcon;