import Head from 'next/head';
import { useRouter } from 'next/router';

import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import NavBar from '../navigates/NavBar';
import Footer from '../footer/Footer';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import IMainProps from '../../interfaces/IMainProps'

interface ScrollTopProps {
    window?: () => Window;
    children: React.ReactElement;
}

interface IMainLayoutProps {
    props: IMainProps,
    title: string,
    description: string,
    keywords: string,
    children: React.ReactNode
};

export default function MainLayout({ children, title, description, keywords, props }: IMainLayoutProps) {
    const locale = props.locale as string;
    const altLocale = locale === 'ru-RU' ? 'ru-UZ' : 'ru-RU';
    const { pathname } = useRouter();


    const appName = locale === 'ru-RU' ? process.env.appRuName : process.env.appUzName;
    const appUrl = locale === 'ru-RU' ? process.env.appRuURL : process.env.appUzURL;
    const altAppUrl = locale === 'ru-RU' ? process.env.appUzURL : process.env.appRuURL;
    const manifest = locale === 'ru-RU' ? '/rumanifest.json' : '/uzmanifest.json'

    const ScrollTop = (props: ScrollTopProps) => {
        const { children, window } = props;
        const trigger = useScrollTrigger({
            target: window ? window() : undefined,
            disableHysteresis: true,
            threshold: 100,
        });

        const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
            const anchor = (
                (event.target as HTMLDivElement).ownerDocument || document
            ).querySelector('#back-to-top-anchor');

            if (anchor) {
                anchor.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                });
            }
        }



        return (
            <Zoom in={trigger}>
                <Box
                    onClick={handleClick}
                    role='presentation'
                    sx={{ position: 'fixed', bottom: 50, right: 20 }}
                >
                    {children}
                </Box>
            </Zoom>
        );
    }


    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                minHeight: '100vh',
                color: 'light',
                background: 'dark'
            }}
        >
            <Head>
                <title>{title} | {process.env.personName} | {appName}</title>
                <meta name='description' content={description} />
                <meta name='keywords' content={keywords} />
                <meta name='author' content='aelinkov.pro' />
                <link rel='manifest' href={manifest} />
                {/* <link rel='icon' type='image/svg+xml' href={`${appUrl}/favicon.svg`} /> */}
                <meta name='apple-mobile-web-app-title' content={appName} />
                <meta name='application-name' content={appName} />
                <meta property='og:type' content='website' />
                <meta property='og:title' content={title} />
                <meta property='og:description' content={description} />
                <meta property='og:url' content={appUrl + pathname} />
                <meta property='og:image' content={`${appUrl}/favicon-org.png`} />
                <meta property='og:image:type' content='image/png' />
                <meta property='og:image:alt' content={appName} />
                <meta property='og:locale' content={locale} />
                <meta property='og:locale:alternate' content={altLocale} />
                <meta property='og:url:alternate' content={altAppUrl + pathname} />
                <meta property='og:profile:username' content={process.env.personName} />
                <meta property='og:profile:gender' content='female' />
            </Head>
            <NavBar {...props} />
            <div id='back-to-top-anchor' />
            <CssBaseline />
            <Box sx={{
                p: '1rem'
            }}
            >
                {children as IMainLayoutProps}
            </Box>
            <ScrollTop >
                <Fab color='warning' size='small' aria-label='scroll back to top'>
                    <KeyboardArrowUpIcon />
                </Fab>
            </ScrollTop>
            <Footer {...props} />
        </Box>
    );
}