import React, { FC } from 'react';

import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import FaceIcon from '@mui/icons-material/Face';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';

interface IContactStackProps {
    locale: string | null,
    fontSizeStack?: string
}


const ContactStack: FC<IContactStackProps> = (props: IContactStackProps) => {
    const theme = useTheme();
    const { locale, fontSizeStack } = props;

    const appMail = locale === 'ru-RU' ? process.env.appRuMail : process.env.appUzMail;
    const appPhone = locale === 'ru-RU' ? process.env.appRuPhone : process.env.appUzPhone;
    const appGeo = locale === 'ru-RU' ? process.env.appRuGeo : process.env.appUzGeo + ', Узбекистан';

    return (
        <Stack direction='column' spacing={1}>
             <Typography sx={{
                fontSize: fontSizeStack,
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap'
            }}>
                <FaceIcon fontSize='inherit' />&nbsp;{process.env.personName}
            </Typography>
            <Typography sx={{
                fontSize: fontSizeStack,
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap'
            }}>
                <LocationOnIcon fontSize='inherit' />&nbsp;{appGeo}
            </Typography>
            <Link color='inherit' title='Позвонить на телефон' href={`tel:${(appPhone as string).replace(/[^+0-9]/g, '')}`}
                sx={{
                    fontSize: fontSizeStack,
                    textDecoration: 'none',
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap'
                }}
            >
                <PhoneIphoneIcon fontSize='inherit' />&nbsp;{appPhone}
            </Link>
            <Link color='inherit' title='Отправить электронную почту' href={`mailto:${appMail}`}
                sx={{
                    fontSize: fontSizeStack,
                    textDecoration: 'none',
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap'
                }}
            >
                <AlternateEmailIcon fontSize='inherit' />&nbsp;{appMail}
            </Link>
        </Stack>
    )
}

export default ContactStack;