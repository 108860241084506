import React from 'react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material/styles';



interface AppDrawerProps {
    pages: {
        name: string,
        slug: string
    }[]
}

const AppDrawer = (props: AppDrawerProps) => {
    const theme = useTheme();
    const { pages } = props;
    const { asPath } = useRouter();
    const anchor = 'right';    //AppDrawer position left, right, top, bottom
    const [state, setState] = React.useState({
        [anchor]: false,
    });

    type Anchor = 'right';

    const toggleDrawer = (anchor: Anchor, open: boolean) => (
        event: React.KeyboardEvent | React.MouseEvent,) => {
        if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' ||
            (event as React.KeyboardEvent).key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };


    const list = (anchor: Anchor) => (
        <Box
            sx={{ width: 250 }}
            role='presentation'
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List component='nav'>
                {pages.map((item) => (
                    <NextLink href={item.slug} key={item.name} passHref>
                        <ListItemButton component='a' drawer-active='true' selected={asPath === '/' + item.slug ? true : false} color='inherit'>
                            <ListItemText sx={{
                                color: asPath === '/' + item.slug ? theme.palette.secondary.main : theme.palette.secondary.dark,
                                display: 'block'
                            }} primary={item.name} />
                        </ListItemButton>
                    </NextLink>
                ))}
            </List>
        </Box >
    );

    return (
        <>
            <IconButton
                edge='start'
                size='large'
                color='secondary'
                aria-label='open drawer'
                onClick={toggleDrawer(anchor, true)}
                title='menu'
                sx={{ mr: 2 }}
            >
                <MenuIcon />
            </IconButton>

            <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
                {list(anchor)}
            </Drawer>
        </>
    );
}
export default AppDrawer;